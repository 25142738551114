import React, {useState} from "react";
import {
    Button,
    FormControl,
    FormGroup,
    Grid, IconButton,
    Input, InputAdornment, InputLabel,
    List,
    ListItem,
    ListSubheader, OutlinedInput,
    Stack,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";

function LoginForm() {
    const [state, setState] = useState({name: "", greetingText: "Enter credentials", password: "", showPassword: false})

    function onTextChanged(event) {
        const {name, value} = event.target
        
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    function onSubmit(event) {
        setState(prevState => {
            return {
                ...prevState,
                greetingText: `Welcome ${prevState.name}`
            }
        })
        event.preventDefault()
    }

    function toggleShowPassword() {
        setState(prevState => {
            return { 
                ...prevState,
                showPassword: !prevState.showPassword
            }
        })
    } 

    return <form onSubmit={onSubmit}>
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ background: 'slategray', borderRadius: 5, p: 2, m: 2 }}>
            <Grid item>
                <p>{state.greetingText}</p>
            </Grid>
            <Grid item>
                <TextField id="outlined-basic" label="What is your name?" variant="outlined" value={state.name} name="name" onChange={onTextChanged} />
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        name="password"
                        onChange={onTextChanged}
                        value={state.password}
                        id="outlined-adornment-password"
                        type={state.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    onMouseDown={toggleShowPassword}
                                    edge="end">
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button type="submit" variant="contained">Submit</Button>
            </Grid>
        </Grid>
    </form>;
}

export default LoginForm;