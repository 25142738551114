function Greeting(){
    var time = new Date().getHours();

    if(time < 12) {
        return "Good morning!"; 
    }
    else if(time < 18){
        return "Good afternoon!"; 
    }
    else {
        return "Good night!"; 
    }
}

function Color(){
    var time = new Date().getHours();

    if(time < 12) { 
        return "Green";
    }
    else if(time < 18){ 
        return "Yellow";
    }
    else { 
        return "Red";
    }
}

export {Greeting, Color};
