import React from "react";
import {Greeting, Color} from "../Helpers/DateTime";

let customStyle = {
    color: Color()
} 

function Welcome() {
    return <div className="heading"> 
        <h1 style={customStyle}>{Greeting()}</h1> 
        <h1>Welcome to Muster Music support</h1>  
    </div>;
}

export default Welcome;