import React from "react";
import Box from "@mui/material/Box";
import {Link, Stack} from "@mui/material"; 
import SupportTopicControl from "../SupportTopicControl";
    

class SupportTopic{
    _title;
    _text;
    _key;
    constructor(key, title, text) {
        this._key = key;
        this._title = title;
        this._text = text;
    }
}

function LoadSupportTopics(){
    return [
        new SupportTopic("46efbaea-b820-42be-ab10-9eb8b103ce14",
            "Subscription to Apple Music", 
            "Muster plays items from the apple music store so a active subscription to apple music is required to play music in this app. You can browse items in the Apple Music store without a subscription, but when you try to play an \n" +
                  "item Muster Music will show a `Join` button that will open a pane to sign in to the service. If the join button does not work, make sure you have apple musics default music app installed."),
        new SupportTopic("0fcb59b6-eab5-4f78-86f7-2b7943bba3a4",
            "Apple devices on MDM profiles",
            "If the web traffic of your apple device is filter with a proxy, be sure to SSL bypass: `https://api.music.apple.com/` This is a required step so without a direct connection to apples api, you will get nothing out of this app."),
        new SupportTopic("fadc6afa-7b8d-483d-bf0c-bd7c65003075",
            "The default Music app",
            "If you remove the default apple Music app from your device it is possible that Muster Music will loose its connection to the apple music apis. If you have problems playing items and are seeing the 'Join' button\n" +
                 "in the player view even tho you have an Apple Music subscription, make sure the default Music app is installed, and try again. Also the 'Join' button will not work correctly without the default Music app installed.")
    ];
}
function SupportTab(){
    function onThumbUpClick(guid){
     console.log("Thumb Up: " + guid)   
    }

    function onThumbDownClick(guid){
        console.log("Thumb Down: " + guid)
    }
    
    return  <Box className="tab_support"  sx={{ width: '100%', height: '100%'}} display='flex'
                 justifyContent="center"
                 alignItems="center"
                 minHeight="100vh" >
        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ width: '80%', height: '80%', overflowY: 'auto'}}  display='flex'
               justifyContent="center"
               alignItems="center">
            {LoadSupportTopics().map(supportTopic => <SupportTopicControl guid={supportTopic._key} title={supportTopic._title} text={supportTopic._text} onThumbUpClick={onThumbUpClick} onThumbDownClick={onThumbDownClick}/>)}
            <Box sx={{ width: '50%', height: '50%'}}  >
                <iframe className="tutorial" src="https://www.youtube.com/embed/Pz3K5supSDw?si=1LTEviFUKCgWLi-F" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Box>
        </Stack>
    </Box>
}                                                                  

export default SupportTab;