import React from "react";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";

function ContactTab(){
    return  <Box className="tab_contact">
        <Stack spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%', height: '100%'}} display='flex'
               justifyContent="center"
               alignItems="center"
               minHeight="100vh">
            <text>Have a question?    </text>
            <a href="mailto:blueskygreenfieldtec@gmail.com">Email me here</a>

            <text>Find a bug?    </text>
            <a href="https://mustermusic.youtrack.cloud">Create an issue here</a>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="business" value="Q2ANLK8FNJ3AW" />
                <input type="hidden" name="no_recurring" value="0" />
                <input type="hidden" name="item_name" value="Buy me a coffee if you find this app useful. (ps: coffee drives future development)" />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </Stack>
        
    </Box>
}                                                                  

export default ContactTab;