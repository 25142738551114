import React, {useState} from "react";
import Welcome from "../Welcome";
import Box from "@mui/material/Box";
import {ClockPicker} from "@mui/lab";
import {Grid, Stack} from "@mui/material";
import LoginForm from "../LoginForm";

function HomeTab(){
    const [time, setTime] = useState(new Date().toLocaleTimeString())
    const [isMouseOverTime, setIsMouseOverTime] = useState(false)
    function Tick() {
        setTime(new Date().toLocaleTimeString())
    }
    
    function OnTimeMouseOver(){
        setIsMouseOverTime(true)
    }

    function OnTimeMouseOut(){
        setIsMouseOverTime(false)
    }

    setInterval(Tick, 1000)
    
    return <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap" sx={{ overflowY: 'auto', height: '80%', width: '100%', py: 5}}  display='flex'
                  justifyContent="center"
                  alignItems="center">
        <Grid container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center">
            <Grid item>
                <h1 onMouseOver={OnTimeMouseOver} onMouseOut={OnTimeMouseOut} style={{ color: isMouseOverTime ? "Red" : "Green" }}>{time}</h1>
            </Grid>
            <Grid item>
                <Welcome/>
            </Grid>
            <Grid item>
                <LoginForm/>
            </Grid>
        </Grid>  
    </Stack>
    
    
}

export default HomeTab;