import React, {useCallback, useEffect, useState} from "react"
import "../styles.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {AppBar} from "@mui/material";
import {ContactPage, HelpCenter, Home} from "@mui/icons-material";
import SupportTab from "./Tabs/SupportTab";
import ContactTab from "./Tabs/ContactTab";
import HomeTab from "./Tabs/HomeTab";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box sx={{ px: '10%', width: '80%', height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Typography sx={{ width: '100%', height: '100%', background: 'darkgray'}}>{children}</Typography>
            )}
        </Box>
    );
}
export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', height: '100%', background: "gray", opacity: '0.8' }}>
            <AppBar position="static" >
                <Tabs value={value} onChange={handleChange} indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example">
                    <Tab icon={<Home/>} label="Home" />
                    <Tab icon={<HelpCenter/>} label="Support" />
                    <Tab icon={<ContactPage/>} label="Contact" />
                </Tabs>
            </AppBar>
            <CustomTabPanel value={value} index={0} >
                <HomeTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}  >
                <SupportTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <ContactTab/>
            </CustomTabPanel>
        </Box>
    );
}