import React from "react";
import {Greeting, Color} from "../Helpers/DateTime";
import {Button, Stack} from "@mui/material";
import {ThumbDown, ThumbUp} from "@mui/icons-material";
 
function Feedback(params) {
    function onThumbUpClick() {
        params.onThumbUpClick(params.guid)
    }

    function onThumbDownClick() {
        params.onThumbDownClick(params.guid)
    }
    
    return <Stack direction="column">
        <p style={{ verticalAlign: "center", bottom: 0}}>Was this helpful?</p>
        <Stack direction="row">
            <Button onClick={onThumbUpClick}><ThumbUp/></Button>
            <Button onClick={onThumbDownClick}><ThumbDown/></Button>
        </Stack> 
    </Stack>;
}

export default Feedback;